.custom-input-mask {
    display: inline-block;
}

.custom-input-mask span {
    display: flex;
    flex-direction: column;
}

.custom-input-mask label {
    font-size: 14px;
}

.input-mask-error {
    margin-top: 2px;
}