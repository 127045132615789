.navbar {
    background-color: var(--darkish-blue);
    border-bottom: 1px solid var(--very-darkish-blue);
    display: flex;
    justify-content: space-between;
}

.navbar-content {
    display: flex;
    padding: 8px;
}

.navbar-content h3 {
    margin-right: 40px;
}

.navbar-end {
    display: flex;
    padding: 8px 16px;
}

.navigations {
    min-height: 40px;
    display: flex;
    flex-direction: row;
    margin-left: 24px;
}

.navigations div {
    display: flex;
    align-items: center;

    height: 100%;
}

.navigations a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    font-size: 14px;

    min-width: 120px;
    height: 100%;
    padding: 0 24px;
}

.navigations a:hover {
    font-weight: bold;
    letter-spacing: 0;
}

.navigations a.active {
    color: #fff;
    font-weight: bold;
    z-index: 1;
}

.navigations a.active::after {
    position: absolute;
    border-radius: 16px;
    z-index: -1;
    content: attr(aria-label);
    font-weight: normal;
    display: flex;
    padding: 8px 16px;
    background-color: var(--blue-grey);
    color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 1200px) {
    .navbar-content {
        padding: 8px var(--medium-vertical-border);
    }
    .navbar-end {
        padding: 8px var(--medium-vertical-border);
    }
}

@media screen and (min-width: 2000px) {
    .navbar-content {
        padding: 8px var(--high-vertical-border);
    }
    .navbar-end {
        padding: 8px var(--high-vertical-border);
    }
}
