.block-order-history-viewer .arrow-wrapper {
    position: relative;
}

/* .block-order-history-viewer .arrow-wrapper .arrow-item::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent red;
    right: -5px;
    top: -5px;
} */
