.cm-light-dialog.p-dialog .p-dialog-header {
    border-bottom: 1px solid var(--alternative-grey-blacked-dark);
    background: var(--pale-blue);
    color: var(--dark-blue);
}
.cm-light-dialog.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: var(--dark-blue);
}
.cm-light-dialog.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: var(--darkish-blue);
}
.cm-light-dialog.p-dialog .p-dialog-content {
    background: var(--pale-blue);
    color: var(--dark-blue);
}
.cm-light-dialog.p-dialog .p-dialog-footer {
    border-top: 1px solid var(--alternative-grey-blacked-dark);
    background: var(--pale-blue);
    color: var(--dark-blue);
}
.cm-light-dialog.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: var(--darkish-blue);
}
.cm-light-dialog.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: var(--darkish-blue);
}

.cm-light-dialog.p-dialog .p-button.p-button-outlined {
    border: 1px solid var(--very-darkish-blue-menu);
    color: var(--very-darkish-blue-menu);
}

/* .cm-light-dialog.p-dialog .p-button {
    background-color: var(--very-darkish-blue-menu);
}

.cm-light-dialog.p-dialog .p-button.p-button-text {
    color: var(--very-darkish-blue-menu);
} */

.cm-light-dialog.p-dialog .p-inputtext,
.cm-light-dialog.p-dialog .p-dropdown,
.cm-light-dialog.p-dialog .p-dropdown-panel,
.cm-light-dialog.p-dialog .p-multiselect,
.cm-light-dialog.p-dialog .p-accordion-header-link,
.cm-light-dialog.p-dialog .p-accordion-content {
    color: var(--dark-blue) !important;
    border: 1px solid var(--light-pale-gray);
    background: var(--light-pale-blue) !important;
}

.cm-light-dialog.p-dialog input,
.cm-light-dialog.p-dialog .p-chips-input-token {
    color: var(--dark-blue) !important;
}

.cm-light-dialog.p-dialog input::placeholder,
.cm-light-dialog.p-dialog textarea::placeholder,
.cm-light-dialog.p-dialog .p-placeholder {
    color: var(--dark-pale-grey) !important;
}

.cm-light-dialog.p-dialog .ether-datetime {
    border: 0;
    background: var(--light-pale-blue);
}

.cm-light-dialog.p-dialog .ether-datetime:hover {
    border: 0;
    background: var(--light-pale-blue);
}

.cm-light-dialog.p-dialog .p-dropdown .p-dropdown-clear-icon {
    color: var(--very-darkish-blue-menu);
}

.cm-light-dialog.p-dialog .p-dropdown .p-dropdown-trigger,
.cm-light-dialog.p-dialog .p-multiselect .p-multiselect-trigger {
    color: var(--dark-blue);
}

.cm-light-dialog.p-dialog .p-menuitem-link {
    background: unset !important;
    border-color: transparent !important;
    color: #031b4e !important;
}

.cm-light-dialog.p-dialog .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: var(--white) !important;
}

.cm-light-dialog.p-dialog .p-tabmenu-nav {
    border-color: var(--white) !important;
}

.cm-light-dialog.p-dialog
    .p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus {
    box-shadow: none !important;
}

.cm-light-dialog.p-dialog .p-accordion .p-button.p-button-text {
    color: var(--dark-pale-grey) !important;
}

.cm-light-dialog.p-dialog .p-datatable .p-datatable-thead > tr > th,
.cm-light-dialog.p-dialog .p-datatable .p-datatable-tbody > tr > td {
    color: var(--dark-pale-grey) !important;
}

.cm-light-dialog.p-dialog .p-datatable .p-datatable-tbody > tr:nth-child(odd) {
    background-color: var(--light-pale-gray);
}
.cm-light-dialog.p-dialog .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: var(--light-pale-blue);
}

.cm-light-dialog.p-dialog
    .p-steps
    .p-steps-item:not(.p-highlight)
    .p-menuitem-link
    .p-steps-number {
    background: var(--pale-blue);
    color: var(--very-darkish-blue);
}

.cm-light-dialog.p-dialog .p-checkbox .p-checkbox-box {
    background: unset;
}
.cm-light-dialog.p-dialog .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: var(--very-darkish-blue-menu);
}
.cm-light-dialog.p-dialog .p-checkbox .p-checkbox-box .p-checkbox-icon:hover {
    color: var(--white);
}