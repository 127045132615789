.paginator-label {
    margin-bottom: 4px;
    font-size: 14px;
}

.no-total-paginator-wrap .p-button,
.no-total-paginator-wrap .p-inputnumber > input,
.no-total-paginator-wrap .p-dropdown {
    border: 0;
    background-color: #1c2c42;
}

.page-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-total-paginator-wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    justify-content: center;
    align-items: flex-end;
}
