.cm-isreview-row.grade-1 {
    background-color: theme('colors.target-grade.1') !important;
}
.cm-isreview-row.grade-2 {
    background-color: theme('colors.target-grade.2') !important;
}
.cm-isreview-row.grade-3 {
    background-color: theme('colors.target-grade.3') !important;
}
.cm-isreview-row.grade-4 {
    background-color: theme('colors.target-grade.4') !important;
}
.cm-isreview-row.grade-5 {
    background-color: theme('colors.target-grade.5') !important;
}

.cm-isreview-row.grade-1 span.p-badge,
.cm-isreview-row.grade-1 .date-badge > span {
    color: theme('colors.target-grade.1') !important;
}
.cm-isreview-row.grade-2 span.p-badge,
.cm-isreview-row.grade-2 .date-badge > span {
    color: theme('colors.target-grade.2') !important;
}
.cm-isreview-row.grade-3 span.p-badge,
.cm-isreview-row.grade-3 .date-badge > span {
    color: theme('colors.target-grade.3') !important;
}
.cm-isreview-row.grade-4 span.p-badge,
.cm-isreview-row.grade-4 .date-badge > span {
    color: theme('colors.target-grade.4') !important;
}
.cm-isreview-row.grade-5 span.p-badge,
.cm-isreview-row.grade-5 .date-badge > span {
    color: theme('colors.target-grade.5') !important;
}

.cm-isreview-row > td,
.cm-isreview-row > td .name-id-aggregator > span,
.cm-isreview-row .cm-target-enrichment-field > div,
.cm-isreview-row i:not(.no-color-override) {
    color: var(--very-darkish-blue-menu) !important;
}

.cm-isreview-row span.p-badge, .cm-isreview-row button.date-badge {
    background-color: var(--very-darkish-blue-menu) !important;
    border: none;
}

