.custom-chips {
    display: inline-block;
}

.custom-chips span {
    display: flex;
    flex-direction: column;
}

.custom-chips label {
    font-size: 14px;
}

.custom-chips .p-chips > .p-inputtext {
    width: 100%;
} 

.chips-error {
    margin-top: 2px;
}